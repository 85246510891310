import initializeTracer from "shared/tracer";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

initializeTracer()
  .then(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
  })
  .catch(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
  });
